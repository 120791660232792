body {
    margin: 0px !important;
    
}

.danger {
    color: red;
    margin: 0px;
    font-size: 13px;
}

.mantine-ppmets {
    background-color: #FFA540 !important;
}

.myAction {
    margin: 3px;
}

.myTable {
    text-align: center;
}

.text-center {
    width: 100%;
    text-align: center;
}

.upload-image {
    width: 100px;
    height: 100px;
}

.mechanicList li {
    padding: 3px;
    list-style: none;
}

.myFlex {
    display: flex;
    flex-direction: row;
    overflow: scroll;
    width: 1100px !important;
}

.serviceClass {
    width: 120px;
    margin: 10px;
    background-color: aquamarine;
    border-radius: 6px;
    border: 1px goldenrod;
    padding: 10px;
    text-align: center;
}

.ssmImageContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;              /* Spacing between image boxes */
  }
  
  .ssmClass {
    width: 75px;
    height: 75px;          /* Fixed height for the container */
    background-color: white;
    border-radius: 6px;
    border: 1px solid goldenrod;
    padding: 5px;
    display: flex;
    justify-content: center; /* Center the image horizontally */
    align-items: center;     /* Center the image vertically */
    overflow: hidden;        /* Ensure no overflow */
  }
  
  .ssmImage {
    max-width: 100%;        /* Ensure image fits within the width */
    max-height: 100%;       /* Ensure image fits within the height */
    object-fit: contain;    /* Contain the image within the box without cropping */
  }
  

.mantine-1kf8xv9:hover {
    background-color: #f2a24b;
}

.daypqs {
    background: #5701f7 !important;
    border: 2px solid #5701f7 !important;
}

.mantine-4zmtpw .rdt_TableCell div:first-of-type {
    padding: 0px !important;
}

.mantine-121w2fi {
    font-weight: 800;
}

.ssm-files {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}

.mantine-List-root {
    list-style: none;
}

.ql-align-right {
    text-align: right;
}

.ql-align-center {
    text-align: center;
}


.ql-snow .ql-picker.ql-font {
  width: 180px !important;
  white-space: nowrap;
}

/* CSS for Quill editor */
/* Set droplist names - -item is the currently selected font, -label is the font's appearance in the droplist */
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='']::before {
    content: 'Default';
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='magneto']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='magneto']::before {
    content: 'Magneto';
    font-family: 'Magneto', sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='times-new-roman']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='times-new-roman']::before {
    content: 'Times New Roman';
    font-family: 'Times New Roman', serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='lucida-calligraphy']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='lucida-calligraphy']::before {
    content: 'Lucida Calligraphy';
    font-family: 'Lucida Calligraphy', cursive;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='comic-sans']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='comic-sans']::before {
    content: 'Comic Sans';
    font-family: 'Comic Sans MS', sans-serif;
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value='arial']::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value='arial']::before {
    content: 'Arial';
    font-family: 'Arial', sans-serif;
}

/****************************************************
Set the font-family content used for the HTML content.
*****************************************************/
.ql-font-magneto {
    font-family: 'Magneto', sans-serif;
}

.ql-font-times-new-roman {
    font-family: 'Times New Roman', serif;
}

.ql-font-lucida-calligraphy {
    font-family: 'Lucida Calligraphy', cursive;
}

.ql-font-comic-sans {
    font-family: 'Comic Sans MS', sans-serif;
}

.ql-font-arial {
    font-family: 'Arial', sans-serif;
}

  

/* .quill h1{
          font-family: 'Poppins' !important;
        } */

.ql-snow .ql-editor h1 {
    font-family: "Poppins" !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="16px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
    content: "16";
    font-size: 16px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="24px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before {
    content: "24";
    font-size: 16px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="32px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="32px"]::before {
    content: "32";
    font-size: 32px !important;
}


.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="40px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="40px"]::before {
    content: "40";
    font-size: 40px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="48x"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="48px"]::before {
    content: "48";
    font-size: 48px !important;
}
/* end quill css */


body{
    background-color: #f9fafb !important;
}

.bg-secondary{
    background-color: #f9fafb !important;
}
.text-primary{
    color: #4C7B5F !important;
}
.text-blue{
    color: #004EEB !important;
}
.text-green{
    color: #12B76A !important;
}
.btn-primary{
    background-color: #4C7B5F !important;
    color: #fff !important;
    border: none !important;
}

.form-check-input:checked{
    border-color: #4C7B5F;
    background-color: #4C7B5F;
}

.deletion-data li {
    margin-bottom: 1rem !important;
}

.otp-field{
    width: 60px !important;
    height: 60px !important;
    border: 1px solid #4C7B5F !important;
    font-size: 32px !important;
    font-weight: bold !important;
    color: #4C7B5F !important;
    -webkit-appearance: none;
    appearance: none;
}

@media only screen and (max-width: 600px) {
  .otp-field{
    width: 42px !important;
    height: 40px !important;
    font-size: 14px !important; 
} 
  .font-resend{
    font-size: 13px !important;
  }
}
.shadow{
    box-shadow: -1px 5px 16px -7px rgba(0,0,0,0.75);
    -webkit-box-shadow: -1px 5px 16px -7px rgba(0,0,0,0.75);
    -moz-box-shadow: -1px 5px 16px -7px rgba(0,0,0,0.75);
}

input:focus {
  outline: none;
  border: 3px solid #B3D0BF !important;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
/* input[type=number] {
  -moz-appearance: textfield;
} */

