body {
    margin: 0;
    line-height: normal;
  }

.logo-icon {
    height: 2.763rem;
    width: 8.194rem;
    position: relative;
  }
  .dashboard {
    margin: 0;
    position: relative;
    font-size: inherit;
    font-weight: 500;
    font-family: inherit;
    display: inline-block;
    min-width: 7rem;
  }
  .logo-parent,
  .main-layout {
    align-self: stretch;
    display: flex;
    align-items: flex-start;
  }
  .logo-parent {
    flex-direction: row;
    justify-content: space-between;
    gap: 1.25rem;
  }
  .main-layout {
    background-color: #e1e1e1;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1.125rem 0.937rem;
    text-align: center;
    font-size: 1.75rem;
    color: #222;
    font-family: Ubuntu;
  }
  .dashboard1,
  .dashboard2 {
    position: relative;
    font-weight: 500;
    display: inline-block;
  }
  .dashboard1 {
    text-transform: uppercase;
    min-width: 7.438rem;
  }
  .dashboard2 {
    color: #202839;
    text-align: center;
    min-width: 5.625rem;
  }
  .navigation-title,
  .navigation-title-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .navigation-title {
    flex-direction: row;
    padding: 0 1.25rem 0 0;
    gap: 0.75rem;
  }
  .navigation-title-wrapper {
    width: 20.219rem;
    flex-direction: column;
    max-width: 100%;
  }
  .dashboard3,
  .dashboard4 {
    position: relative;
    font-weight: 500;
    display: inline-block;
    min-width: 4.313rem;
  }
  .dashboard4 {
    color: #202839;
    min-width: 6.063rem;
  }
  .navigation-items,
  .navigation-title1 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  .navigation-title1 {
    justify-content: flex-start;
    gap: 0.75rem;
    text-align: center;
  }
  .navigation-items {
    align-self: stretch;
    justify-content: space-between;
    max-width: 100%;
    gap: 1.25rem;
  }
  .dashboard5 {
    position: relative;
    text-transform: uppercase;
    font-weight: 600;
    display: inline-block;
    min-width: 5.375rem;
  }
  .content-header {
    align-self: stretch;
    background-color: #ffa540;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0.5rem 0.75rem;
  }
  .dashboard6 {
    flex: 1;
    position: relative;
    font-weight: 600;
  }
  .grid-item {
    width: 9.5rem;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0.5rem 0.75rem;
    box-sizing: border-box;
  }
  .dashboard7 {
    position: relative;
    font-weight: 600;
    display: inline-block;
    min-width: 0.25rem;
  }
  .grid-item1 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0.5rem;
  }
  .dashboard8 {
    flex: 1;
    position: relative;
    display: inline-block;
    max-width: 100%;
  }
  .grid-item-row,
  .grid-item2 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  }
  .grid-item2 {
    flex: 1;
    padding: 0.5rem;
    box-sizing: border-box;
    min-width: 15.938rem;
  }
  .grid-item-row {
    align-self: stretch;
    flex-wrap: wrap;
    row-gap: 20px;
  }
  .dashboard9 {
    flex: 1;
    position: relative;
    font-weight: 600;
  }
  .dashboard-wrapper {
    width: 9.5rem;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0.5rem 0.75rem;
    box-sizing: border-box;
  }
  .dashboard10 {
    position: relative;
    font-weight: 600;
    display: inline-block;
    min-width: 0.25rem;
  }
  .dashboard-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0.5rem;
  }
  .dashboard11 {
    flex: 1;
    position: relative;
    display: inline-block;
    white-space: nowrap;
    max-width: 100%;
  }
  .dashboard-frame,
  .grid-item-row1 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  }
  .dashboard-frame {
    flex: 1;
    padding: 0.5rem;
    box-sizing: border-box;
    min-width: 15.938rem;
  }
  .grid-item-row1 {
    align-self: stretch;
    flex-wrap: wrap;
    row-gap: 20px;
  }
  .dashboard12 {
    flex: 1;
    position: relative;
    font-weight: 600;
  }
  .frame-div {
    width: 9.5rem;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0.5rem 0.75rem;
    box-sizing: border-box;
  }
  .dashboard13 {
    position: relative;
    font-weight: 600;
    display: inline-block;
    min-width: 0.25rem;
  }
  .dashboard-wrapper1 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0.5rem;
  }
  .dashboard14 {
    flex: 1;
    position: relative;
    display: inline-block;
    white-space: nowrap;
    max-width: 100%;
  }
  .dashboard-wrapper2,
  .grid-item-row2 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  }
  .dashboard-wrapper2 {
    flex: 1;
    padding: 0.5rem;
    box-sizing: border-box;
    min-width: 15.938rem;
  }
  .grid-item-row2 {
    align-self: stretch;
    flex-wrap: wrap;
    row-gap: 20px;
  }
  .dashboard15 {
    flex: 1;
    position: relative;
    font-weight: 600;
  }
  .dashboard-wrapper3 {
    width: 9.5rem;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0.5rem 0.75rem;
    box-sizing: border-box;
  }
  .dashboard16 {
    position: relative;
    font-weight: 600;
    display: inline-block;
    min-width: 0.25rem;
  }
  .dashboard-wrapper4 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0.5rem;
  }
  .dashboard17 {
    flex: 1;
    position: relative;
    display: inline-block;
    max-width: 100%;
  }
  .dashboard-wrapper5,
  .grid-item-row3 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  }
  .dashboard-wrapper5 {
    flex: 1;
    padding: 0.5rem;
    box-sizing: border-box;
    min-width: 15.938rem;
  }
  .grid-item-row3 {
    align-self: stretch;
    flex-wrap: wrap;
    row-gap: 20px;
  }
  .dashboard18 {
    flex: 1;
    position: relative;
    font-weight: 600;
  }
  .dashboard-wrapper6 {
    width: 9.5rem;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0.5rem 0.75rem;
    box-sizing: border-box;
  }
  .dashboard19 {
    position: relative;
    font-weight: 600;
    display: inline-block;
    min-width: 0.25rem;
  }
  .dashboard-wrapper7 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0.5rem;
  }
  .dashboard20 {
    flex: 1;
    position: relative;
    display: inline-block;
    max-width: 100%;
  }
  .dashboard-wrapper8,
  .grid-item-row4 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    max-width: 100%;
  }
  .dashboard-wrapper8 {
    flex: 1;
    padding: 0.5rem;
    box-sizing: border-box;
    min-width: 15.938rem;
  }
  .grid-item-row4 {
    align-self: stretch;
    flex-wrap: wrap;
    row-gap: 20px;
  }
  .dashboard21 {
    flex: 1;
    position: relative;
    font-weight: 600;
  }
  .dashboard-wrapper9 {
    width: 9.5rem;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0.5rem 0.75rem;
    box-sizing: border-box;
  }
  .dashboard22 {
    position: relative;
    font-weight: 600;
    display: inline-block;
    min-width: 0.25rem;
  }
  .dashboard-wrapper10 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0.5rem;
  }
  .dashboard23 {
    flex: 1;
    position: relative;
    display: inline-block;
    white-space: nowrap;
    max-width: 100%;
  }
  .dashboard-wrapper11,
  .grid-item-row5 {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .dashboard-wrapper11 {
    flex: 1;
    padding: 0.5rem;
    box-sizing: border-box;
    min-width: 15.938rem;
    max-width: 100%;
  }
  .grid-item-row5 {
    flex-wrap: wrap;
    row-gap: 20px;
  }
  .content-area,
  .dashboard-grid,
  .grid-item-row5 {
    align-self: stretch;
    max-width: 100%;
  }
  .dashboard-grid {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .content-area {
    border-radius: 3px;
    border: 1px solid #e9e9e9;
    box-sizing: border-box;
    overflow: hidden;
    font-size: 0.75rem;
    color: #222;
    font-family: "Source Sans Pro";
  }
  .admin,
  .content-area,
  .navigation-panel {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .navigation-panel {
    align-self: stretch;
    padding: 2rem 0.937rem 8.312rem;
    box-sizing: border-box;
    gap: 1.5rem;
    max-width: 100%;
    text-align: left;
    font-size: 0.875rem;
    color: #ffa540;
    font-family: Ubuntu;
  }
  .admin {
    width: 100%;
    position: relative;
    line-height: normal;
    letter-spacing: normal;
  }
  @media screen and (max-width: 525px) {
    .navigation-items {
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  @media screen and (max-width: 450px) {
    .dashboard {
      font-size: 1.375rem;
    }
    .logo-parent {
      flex-wrap: wrap;
    }
  }
  